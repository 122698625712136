<!-- <div>
  <aside><img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/4424790/Mirror.png" alt="404 Image" />
  </aside>
  <main>
    <h1>Sorry!</h1>
    <p>
      Either you aren't cool enough to visit this page or it doesn't exist <em>. . . like your social life.</em>
    </p>
    <button [routerLink]="['/auth/login']">You can go now!</button>
  </main>
</div> -->
<div
  class="login-section"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign.xs="center center"
  fxLayoutAlign="start center"
>
  <div fxFlex.gt-xs="50" class="login" ngClass.xs="full-width">
    <div fxLayout="column" ngStyle.xs="margin:0 50px;">
      <h1 class="heading text-center">PAGE NOT FOUND</h1>
      <p class="second-line text-center">
        Seems you are looking for something that doesn't exist.
      </p>
      <div
        class="text-center mt-6 cta-section"
        fxLayout="row"
        fxLayoutAlign.xs="spacebetween center"
      >
        <button mat-raised-button color="warn" (click)="back()">Go Back</button>
        <button
          mat-raised-button
          color="warn"
          [routerLink]="['/admin/dashboard']"
        >
          Return Home
        </button>
      </div>
    </div>
  </div>
  <div
    fxHide.xs
    fxFlex="50"
    class="logo-section second-login full-height"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <img src="../../../assets/images/Water.png" alt="" />
  </div>
</div>
