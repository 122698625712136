import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName'
})
export class FullNamePipe implements PipeTransform {

  transform(data: any, ...args: unknown[]): unknown {

    if (!data?.first_name) {
      return '-';
    }

    return data?.first_name + ' ' + data?.last_name;
  }

}
