<div
  class="section-header posR {{ class[0] }}"
  fxLayout="row"
  fxLayoutAlign="space-between center"
  fxLayoutGap="5px"
>
  <div class="header-right-section {{ class[1] }}">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="space-right">
        <mat-icon svgIcon="icon-ico-star"></mat-icon>
      </div>
      <h3>
        <ng-content select="#headerLabel"></ng-content>
      </h3>
    </div>
  </div>
  <div class="border-section"></div>
  <div class="button-section" *ngIf="isButtonShow">
    <button
      mat-icon-button
      flex
      fxLayoutAlign="space-between center"
      (click)="clickHandler()"
    >
      <ng-content select="#buttonLabel2"></ng-content>
    </button>
    <button
      class="heading-button"
      mat-raised-button
      color="warn"
      (click)="goTo()"
      [disabled]="isdisabled"
    >
      <ng-content select="#buttonLabel"></ng-content>
    </button>
  </div>
</div>
