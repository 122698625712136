import { Injectable, NgZone } from '@angular/core';

import { NavigationService } from './navigation.service';
import { SharedDataService } from './shared-data.service';
import { NotificationService } from './notification.service';
import { Observable } from 'rxjs';
import { LoadingService } from './loader.service';
import { DataService } from './data.service';
import { ApiUrls } from '../constants/apiUrl.constants';

@Injectable()
export class AuthService {

  redirectUrl: string;
  isChecked = false;
  // tslint:disable-next-line:max-line-length
  constructor(
    private sharedData: SharedDataService,
    private nav: NavigationService,
    private dataService: DataService,
    private notification: NotificationService,
    private ngZone: NgZone,
    private loader: LoadingService

  ) {
    // Setting default route to navigate after login
    this.redirectUrl = '/admin/dashboard';
  }

  getAccessToken() {
    return this.sharedData.getAttribute('accessToken');
  }

  login(userData: any) {
    this.isChecked = true;
    // Referencing this in this because this refers to the promise itthis. Do this let this = this;
    const data = {
      'value': userData,
      'withCredentials': false
    };

    this.dataService.post({ url: ApiUrls.Admin_Authentication, data: data, isLoader: true })
      .subscribe((response: any) => {
        const result = response.result;
        if (response.result !== undefined) {

          this.sharedData.setAttribute('userDetails', result);
          this.sharedData.setAttribute('accessToken', result.access_token);
          this.sharedData.setAttribute('refreshToken', result.refresh_token);

          this.getApplicationConstant();
          if (!result?.user_details?.is_email_verified) {
            this.nav.navigateTo(['/auth/email-verification']);
          } else {
            this.nav.navigateTo([this.redirectUrl]);
          }
        } else {
          this.notification.toast(response.error);
        }
        this.isChecked = false;
      },
        () => { this.isChecked = false; }
      );
  }

  sentEmailVerification(){
    this.dataService.post({ url: ApiUrls.Email_OTP }).subscribe((response: any) => {
      if (response && response.result) {
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }

  emailVerify(passcode: any) {
    const userDetail = this.sharedData.getAttribute('userDetails')?.user_details;
    this.dataService.get({ url: ApiUrls.Email_Verification + '/' + userDetail.email + '/'+ passcode }).subscribe((response: any) => {
      if (response && response.result) {
        this.nav.navigateTo([this.redirectUrl]);
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }

  getApplicationConstant() {
    this.dataService.get({
      url: ApiUrls.Application_Constant,
      isLoader: false
    }).subscribe((response: any) => {
      if (response && response.result) {
        this.sharedData.setAttribute('applicationConstant', response.result);
      }
    });
  }

  redirectAfterLogout() {
    this.ngZone.run(() => {
      this.loader.stop();
      console.log('Refresh Token expired');
      this.sharedData.clear();
      this.nav.navigateTo(['/auth/login']);
    });
  }

  isLoggedIn() {
    return Boolean(this.sharedData.getAttribute('accessToken') && this.sharedData.getAttribute('userDetails'));
  }
  getRefreshToken() {
    return this.sharedData.getAttribute('refreshToken');
  }

  refreshAccessToken(): Observable<any> {
    const data = { refresh_token: this.getRefreshToken() };
    return this.dataService.post({ url: ApiUrls.Admin_Refresh_Token, data, isLoader: false });
  }

  logout() {
    this.dataService.post({ url: ApiUrls.Admin_Logout }).subscribe((result: any) => {
      if (result) {
        this.sharedData.clear();
        this.isChecked = false;
        this.nav.navigateTo(['/auth/login']);
      }
    });
  }

  resetPassword(userData: any) {
    const data = {
      'value': userData,
      'withCredentials': false
    };
    this.dataService.post({ url: ApiUrls.Admin_Password_Reset, data: data }).subscribe((result: any) => {
      console.log(result);
      this.nav.navigateTo(['/auth/login']);
      this.notification.toast('Check Your Mail ! Click on reset link.');
    },
      () => { }
    );
  }

  forgetPassword(userData: any) {
    // Referencing this in self because this refers to the promise itself. Do this let self = this;
    const self = this,
      data = {
        value: userData,
        noCredentials: true
      };

    this.dataService.post({ url: ApiUrls.Admin_Forget_Password, data }).subscribe(
      (response: any) => {
        if (response && response.result) {
          console.log(response);
          self.notification.toast('Please check your mail for reset link!');
          this.sharedData.setAttribute('forgetPasswordEmail', userData.email);
          this.nav.navigateTo(['/auth/reset-password']);
        } else if (response.error) {
          self.notification.toast(response.error, 'danger');
        }
      },
      error => { }
    );
  }

  updatePassword(password: any) {
    const data = {
      value: password,
      noCredentials: false
    };
    this.dataService.post({ url: ApiUrls.Admin_Password_Change, data }).subscribe((response: any) => {
      if (response && response.result) {
        this.notification.toast('Password updated successfully!');
        this.nav.navigateTo(['/auth/login']);
        this.sharedData.deleteAttribute('forgetPasswordEmail');
      } else {
        this.notification.toast(response.error, 'danger');
      }
    });
  }

  getUserDetail() {
    return this.isLoggedIn() ? this.sharedData.getAttribute('userDetails').user_details : {};
  }

  userIsAdmin() {
    return this.isLoggedIn() ? this.sharedData.getAttribute('userDetails').user_details.is_admin : false;
  }

  updateLoginUser(formData: any) {
    return this.dataService.put({
      url: ApiUrls.Profile_Update,
      data: formData,
      loaderName: 'container-loader'
    });
  }
}
