import { Injectable } from '@angular/core';
import { DataService } from './data.service';


export interface Config {
  self: any;
  listName: string;
  url: string;
  searchUrl?: string;
  callback?: any;
  skip?: number;
  limit?: number;
  scrollContainer?: any,
  fields?: string[],
  check?: any[],
  scrollDisabled?: boolean,
}

@Injectable({
  providedIn: 'root'
})
export class ListService {

  public isLoading = false;
  public statsData: any;
  public fromRoot = true;
  public scrollDistance = 1;
  public scrollThrottle = 150;
  public scrollDisabled = false; // remove later
  public scrollContainer = '.mat-sidenav-content';
  limit = 15;

  constructor(
    private dataService: DataService
  ) {

  }

  init(config: Config, data = []) {
    config.skip = config.skip || 0;
    config.limit = config.limit || this.limit;
    config.self[config.listName] = [];

    this.scrollContainer = config.scrollContainer || '.mat-sidenav-content';
    this.appendResult(config, data);
  }

  appendResult(config: Config, list: any[], retainLimit?: boolean) {
    if (Array.isArray(list)) {
      config.self[config.listName] = config.skip == 0 ? list : config.self[config.listName].concat(list);

      // How many record need to skip for next time.
      // Enable or disable pagination based on condition.
      if (config.skip !== undefined && config.limit !== undefined) {
        config.skip += list.length;
        config.scrollDisabled = list.length < config.limit;
      }
    } else {
      config.scrollDisabled = true;
    }
  }

  getList(config: any = {}, retainLimit = true) {
    if (config.self.isSearch) {
      this.getSearchList(config);
      return;
    }

    let limit = config.limit;
    if (retainLimit) {
      config.skip = 0;
      const len = config.self[config.listName]?.length;
      limit = len < config.limit ? config.limit : len;
    }

    this.isLoading = true;
    this.dataService.get({
      url: config.url + "skip=" + config.skip + "&limit=" + limit,
      isLoader: false
    }).subscribe((response: any) => {
      this.isLoading = false;
      if (response && response.result) {
        if (typeof config.callback === "function") {
          config.callback(response.result);
        }
        this.appendResult(config, response.result.records, retainLimit);
      }
      if (response.result.stats) {
        this.statsData = response.result.stats;
      }
    }, () => {
      this.isLoading = false;
    });
  }

  setFilterApiUrl(config: any, baseURL: string, filterString: string) {
    config.url = baseURL + filterString;
    this.getList(config);
  }

  appendSearchResult(config: any, list: any[]) {
    if (Array.isArray(list)) {
      config.self[config.listName] = config.skip == 0 ? list : config.self[config.listName].concat(list);
      // How many record need to skip for next time.
      // Enable or disable pagination based on condition.
      config.skip += list.length;

      config.scrollDisabled = list.length < config.limit;

      if (typeof config.callback === "function") {
        config.callback();
      }
    } else {
      config.scrollDisabled = true;
    }
  }

  getSearchList(config: any = {}) {
    if (!config.self.isSearch) {
      this.getList(config);
      return;
    }

    const data = {
      query: {
        keyword: config.self.searchText.replace(/[`~!#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi, ''),
        fields: config.fields,
        offset: config.skip,
        limit: config.limit,
        check: config.check || []
      }
    };

    this.isLoading = true;
    this.dataService.post({
      url: config.searchUrl,
      isLoader: false,
      data
    }).subscribe((response: any) => {
      this.isLoading = false;
      if (response && response.result) {
        this.appendSearchResult(config, Array.isArray(response.result.records) ? response.result.records : response.result);
      }
    }, () => {
      this.isLoading = false;
    });
  }

  search(config: any = {}) {
    if (config.self.searchText) {
      config.self.isSearch = true;
      config.skip = config.skip = 0;
      config.limit = config.limit = this.limit;
      this.getSearchList(config);
    } else if (config.self.searchText === '') {
      this.clearSearch(config);
    }
  }

  clearSearch(config: any = {}) {
    config.self.searchText = '';
    config.self.isSearch = false;
    config.skip = config.skip = 0;
    config.limit = config.limit = this.limit;
    this.getList(config);
  }
}
