import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SystemService } from 'src/app/pages/system/system.service';
import { ApiUrls } from '../../constants/apiUrl.constants';
import { AuthService } from '../../services/auth.service';
import { Config, ListService } from '../../services/list.service';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.scss'],
  providers: [ListService]
})
export class ActivityDetailComponent implements OnInit {
  systemInfo: any = {};
  logType = '';
  logDetail: any = {};
  @Output() toggle = new EventEmitter();
  userDetails: any = {};
  lists: any;
  scrollDisabled = true;
  systemLogs: any[] = [];
  systemHistoryList: any[] = [];
  id: any;
  historyConfig: Config | undefined;
  isShowLogDetail: boolean = false;
  connectionActivities: any;
  connectivityHeader: string = '';

  constructor(
    private systemService: SystemService,
    private authService: AuthService,
    public listService: ListService,
    private dataService: DataService
  ) {
    this.userDetails = this.authService.getUserDetail();
  }

  ngOnInit(): void {

  }

  getSystemLogsList(id: string, log_key = '') {
    setTimeout(() => {
      this.scrollDisabled = false;
    }, 0);

    this.id = id;
    this.lists = [{
      self: this,
      listName: "systemLogs",
      url: ApiUrls.Logs + '?system=' + id + '&',
      scrollContainer: '.sidebar-list-view'
    }];

    this.systemService.getSystemLogsList(id, log_key).subscribe((response: any) => {
      if (response && response.result) {
        this.listService.init(this.lists[0], response.result.records);
      }
    });
  }

  getLogInformation(id: string, log_key = '', isShowLogDetail = true) {
    this.logType = 'all';
    this.isShowLogDetail = isShowLogDetail;
    this.systemService.getSystemInfo(id, 'log-information')
      .subscribe((response: any) => {
        if (response && response.result) {
          this.systemInfo = response.result;
          this.getSystemLogsList(id, log_key);
        }
      });
  }

  getPickSystem() {
    this.logType = 'picksystem';
  }

  getPickProject() {
    this.logType = 'pickproject';
  }

  getConnectivityDetails(type: string, header: string) {
    this.logType = 'connectivity';
    this.connectivityHeader = header;

    this.dataService.get({
      url: ApiUrls.System_List + '?system_status=' + type,
      loaderName: 'log-information'
    }).subscribe((response: any) => {
      console.log(response);
      if (response && response.result) {
        this.connectionActivities = response.result.records;
      }
    });
  }
  
  getSameLogHistory(id: string, log_key: any) {
    this.historyConfig = {
      self: this,
      listName: "systemHistoryList",
      url: ApiUrls.Logs + '?system=' + id + '&log_key=' + log_key + '&',
      skip: 0,
      limit: 15,
      scrollContainer: '.sidebar-list-view'
    };

    this.systemService.getSystemLogsList(id, log_key)
      .subscribe((response: any) => {
        if (response && response.result) {
          console.log(response.result);

          this.listService.init(this.historyConfig!, response.result.records);
        }
      });
  }

  sidebarToggle() {
    this.toggle.emit();
  }
 
}
