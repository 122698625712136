import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/core/services/auth.service';
import { DialogService } from 'src/app/core/services/dialog.service';
import { SharedDataService } from 'src/app/core/services/shared-data.service';
import { GlobalFilterComponent } from '../dashboard/global-filter/global-filter.component';
import { Router, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {
  userDetail: any;
  menus: any[];
  constructor(
    private dialogService: DialogService,
    private auth: AuthService, private router: Router, private sharedData: SharedDataService
  ) {
    this.userDetail = this.auth.getUserDetail();

    this.menus = [
      { name: 'Dashboard', iconName: 'icon-ico-dashboard', link: '/admin/dashboard', isShow: false },
      { name: 'Dashboard', iconName: 'icon-ico-dashboard', link: '/admin/dashboard', isShow: true },
      { name: 'Active Alerts', iconName: 'icon-ico-active-alerts', link: '/admin/activity-alerts', isShow: true },
      { name: 'Logs', iconName: 'icon-ico-logs', link: '/admin/logs', isShow: true },
      { name: 'Pools', iconName: 'icon-ico-systems', link: '/admin/pools', isShow: true },
      { name: 'Systems', iconName: 'icon-ico-systems', link: '/admin/system', isShow: true },
      { name: 'New Systems', iconName: 'icon-ico-systems', link: '/admin/new-system', isShow: false },
      { name: 'Segments', iconName: 'icon-ico-users', link: '/admin/manage-system-segment', isShow: true },
      { name: 'Action Tasks', iconName: 'icon-ico-users', link: '/admin/action-center', isShow: true },
      { name: 'Action Types', iconName: 'icon-ico-users', link: '/admin/action-type', isShow: true },
      { name: 'Customers', iconName: 'icon-ico-customers', link: '/admin/customers', isShow: this.userDetail.is_admin },
      { name: 'Users', iconName: 'icon-ico-users', link: '/admin/users', isShow: this.userDetail.is_admin },
      { name: 'Settings', iconName: 'icon-ico-settings', link: '/admin/settings', isShow: this.userDetail.is_admin }
    ];

    this.sharedData.changeEmitted$.subscribe(changeText => {
      if (changeText === 'profile_updated') {
        this.userDetail = this.auth.getUserDetail();
      }
    });
  }

  openFilter() {
    this.dialogService.openDialogComponent(GlobalFilterComponent, { class: ['custom-dialog'] }, '880px');
  }

  logout() {
    this.auth.logout();
  }
  isActive(link: string): boolean {
    return this.router.isActive(link, true);
  }
}
